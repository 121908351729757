/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** Overrides */
  --ion-text-color: var(--secondary-color);
  --ion-background-color: var(--background);

  /** primary **/
  --ion-color-primary: var(--primary-color);
  --ion-color-primary-rgb: var(--primary-color-rgb);
  --ion-color-primary-contrast: var(--secondary-color);
  --ion-color-primary-contrast-rgb: var(--secondary-color-rgb);
  --ion-color-primary-shade: var(--primary-shade);
  --ion-color-primary-tint: var(--primary-tint);

  /** secondary **/
  --ion-color-secondary: var(--secondary-color);
  --ion-color-secondary-rgb: var(--secondary-color-rgb);
  --ion-color-secondary-contrast: var(--primary-color);
  --ion-color-secondary-contrast-rgb: var(--primary-color-rgb);
  --ion-color-secondary-shade: var(--secondary-shade);
  --ion-color-secondary-tint: var(--secondary-tint);

  /** tertiary **/
  --ion-color-tertiary: var(--tertiary-color);
  --ion-color-tertiary-rgb: 255, 130, 59;
  --ion-color-tertiary-contrast: var(--secondary-color);
  --ion-color-tertiary-contrast-rgb: var(--secondary-color-rgb);
  --ion-color-tertiary-shade: var(--tertiary-shade);
  --ion-color-tertiary-tint: var(--tertiary-tint);

  /** success **/
  --ion-color-success: var(--success);
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: var(--warning);
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: var(--danger);
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /* custom */
  --ion-color-primary-brand: var(--secondary-color);
  --ion-color-primary-brand-rgb: var(--secondary-color-rgb);
  --ion-color-primary-brand-contrast: var(--primary-color);
  --ion-color-primary-brand-contrast-rgb: var(--primary-color-rgb);
  --ion-color-primary-brand-shade: var(--secondary-shade);
  --ion-color-primary-brand-tint: var(--secondary-tint);

  --ion-color-white-background: var(--primary-color);
  --ion-color-white-background-rgb: rgb(238, 237, 228);
  --ion-color-white-background-contrast: var(--secondary-color);
  --ion-color-white-background-contrast-rgb: var(--secondary-color-rgb);
  --ion-color-white-background-shade: var(--primary-shade);
  --ion-color-white-background-tint: var(--primary-tint);

  --ion-color-black-text: var(--secondary-color);
  --ion-color-black-text-rgb: var(--secondary-color-rgb);
  --ion-color-black-text-contrast: var(--primary-color);
  --ion-color-black-text-contrast-rgb: var(--primary-color-rgb);
  --ion-color-black-text-shade: var(--secondary-shade);
  --ion-color-black-text-tint: var(--secondary-tint);

  --ion-color-gray-text: #909090;
  --ion-color-gray-text-rgb: 144, 144, 144;
  --ion-color-gray-text-contrast: #000000;
  --ion-color-gray-text-contrast-rgb: 0, 0, 0;
  --ion-color-gray-text-shade: #7f7f7f;
  --ion-color-gray-text-tint: #9b9b9b;

  --ion-color-light-gray: #eaeaea;
  --ion-color-light-gray-rgb: 234, 234, 234;
  --ion-color-light-gray-contrast: #000000;
  --ion-color-light-gray-contrast-rgb: 0, 0, 0;
  --ion-color-light-gray-shade: #cecece;
  --ion-color-light-gray-tint: #ececec;

  --ion-color-white-text: var(--primary-color);
  --ion-color-white-text-rgb: var(--primary-color-rgb);
  --ion-color-white-text-contrast: var(--secondary-color);
  --ion-color-white-text-contrast-rgb: var(--secondary-color-rgb);
  --ion-color-white-text-shade: var(--primary-shade);
  --ion-color-white-text-tint: var(--primary-tint);
}

.ion-color-primary-brand {
  --ion-color-base: var(--ion-color-primary-brand);
  --ion-color-base-rgb: var(--ion-color-primary-brand-rgb);
  --ion-color-contrast: var(--ion-color-primary-brand-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-brand-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-brand-shade);
  --ion-color-tint: var(--ion-color-primary-brand-tint);
}

.ion-color-white-background {
  --ion-color-base: var(--ion-color-white-background);
  --ion-color-base-rgb: var(--ion-color-white-background-rgb);
  --ion-color-contrast: var(--ion-color-white-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-background-shade);
  --ion-color-tint: var(--ion-color-white-background-tint);
}

.ion-color-transparent-background {
  --ion-color-base: transparent;
  --ion-color-base-rgb: rgba(0, 0, 0, 0);
  --ion-color-contrast: var(--ion-color-white-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-background-shade);
  --ion-color-tint: var(--ion-color-white-background-tint);
}

.ion-color-black-text {
  --ion-color-base: var(--ion-color-black-text);
  --ion-color-base-rgb: var(--ion-color-black-text-rgb);
  --ion-color-contrast: var(--ion-color-black-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-text-shade);
  --ion-color-tint: var(--ion-color-black-text-tint);
}

.ion-color-gray-text {
  --ion-color-base: var(--ion-color-gray-text);
  --ion-color-base-rgb: var(--ion-color-gray-text-rgb);
  --ion-color-contrast: var(--ion-color-gray-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-text-shade);
  --ion-color-tint: var(--ion-color-gray-text-tint);
}

.ion-color-light-gray {
  --ion-color-base: var(--ion-color-light-gray);
  --ion-color-base-rgb: var(--ion-color-light-gray-rgb);
  --ion-color-contrast: var(--ion-color-light-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-gray-shade);
  --ion-color-tint: var(--ion-color-light-gray-tint);
}

.ion-color-white-text {
  --ion-color-base: var(--ion-color-white-text);
  --ion-color-base-rgb: var(--ion-color-white-text-rgb);
  --ion-color-contrast: var(--ion-color-white-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-text-shade);
  --ion-color-tint: var(--ion-color-white-text-tint);
}
