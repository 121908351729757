@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .font-outline {
        -webkit-text-stroke: 1px black;
    }
    .font-outline-2 {
        -webkit-text-stroke: 1px black;
    }
    .font-outline-4 {
        -webkit-text-stroke: 4px black;
    }
}