:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  --ion-font-family: 'Inter', sans-serif;

  @apply bg-neutral-800 text-neutral-100;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.font-normal-override {
    font-family: 'Inter Book', sans-serif;
    font-weight: normal;
    --ion-font-family: 'Inter Book', sans-serif;
    --ion-font-weigth: normal;
}

.capitalise-first-letter::first-letter {
  text-transform: capitalize;
}

.theme-shine {
  box-shadow: var(--theme-shine);
}

.theme-shine-icon {
  overflow: visible;
  color: #EEEDE4;

  -webkit-filter: drop-shadow(1px 1px 8px #F4FF10);
  filter: drop-shadow(1px 1px 8px #F4FF10);
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.theme-underline {
  border-bottom: 2px solid var(--tertiary-color);
}

.theme-card-shadow {
  box-shadow: var(--theme-card);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-size: 14px;
  @apply text-neutral-400;
}

a {
  text-decoration: underline;
}

h1 {
  @apply select-none text-3xl font-bold leading-10 tracking-tight text-neutral-50 mb-2;
}

h2 {
  @apply select-none text-2xl font-bold leading-9 tracking-tight text-neutral-50 mb-1;
}

h3 {
  @apply select-none text-xl font-bold leading-9 tracking-tight text-neutral-50;
}

h4,
h5,
h6,
button {
  font-size: 20px;
}

hr {
  border-color: var(--secondary-color-rgb);
  opacity: 0.3;
}

.truncate-text-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.skeleton-text {
  height: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.skeleton-h1 {
  height: 28px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


ion-backdrop {
  background: rgba(30, 30, 30, 0.6);
  position: absolute;
  height: 100vh;
  width: 100vw;
}

/** Global Overrides */
ion-toolbar {
  --background: transparent;
  --opacity: 0.5;
  --border-width: 0;
  --border-color: transparent;
}

ion-item::part(native) {
  --background: transparent;
  --border-color: transparent;
}

ion-button {
  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 0;
  --background-activated: var(--secondary-shade);
  --background-focused: var(--secondary-shade);
  --background-hover: unset;
  margin: 0;
  height: 45px!important;
  min-height: 40px;
  width: 100%;
}

ion-button::part(native) {
  border-radius: var(--rounding);
  font-size: 20px;
  height: 45px!important;
  max-height: 45px;
}

ion-button.lighter::part(native) {
  --background: var(--primary-color);
  --background-activated: var(--primary-shade);
  --background-focused: var(--primary-shade);

  color: var(--secondary-color);
}

ion-button.slim {
  height: 40px;
}

ion-input::part(native) {
  --highlight-color-focused: var(--secondary-color);
}


ion-datetime-button::part(native) {
  text-align: left;
}

ion-datetime-button.text-center::part(native) {
  text-align: center;
}

ion-datetime-button.transparent::part(native) {
  background-color: transparent;
}

ion-segment {
  background: var(--background);
}

ion-segment-button::part(indicator-background) {
  background: var(--primary-color);
}

ion-segment-button::part(native) {
  color: var(--secondary-color);
}

ion-datetime-button::part(native) {
  flex: 1;
}

.segment-button-checked::part(native) {
  color: var(--primary-color);
}

.h-tabs {
  height: 70px;
}

/** Gives something a fullscreen height, except for the height of the tabs-page. Remember to keep this in sync with tabs-page height  */
.h-full-above-tabs {
  height: calc(100% - 70px);
}

.mask-fade-out {
  mask-image: linear-gradient(#000 80%, rgba(0, 0, 0, 0) 92%, rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0) 80%, rgba(0, 0, 0, 0) 92%, rgba(0, 0, 0, 0));
}

.mask-fade-out-broad {
  mask-image: linear-gradient(#000 70%, rgba(0, 0, 0, 0) 94%, rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0) 70%, rgba(0, 0, 0, 0) 94%, rgba(0, 0, 0, 0));
}

.mask-fade-out-weak {
  mask-image: linear-gradient(rgba(0, 0, 0) 70%, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0) 80%, rgba(0, 0, 0, 0.3) 95%, rgba(0, 0, 0, 0));
}

.center-spinner {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/** Input and selected colors */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px var(--primary-color) inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: var(--secondary-color) !important;
}

::-moz-selection { /* Code for Firefox */
  color: var(--secondary-color);
  background: var(--tertiary-color);
}

::selection {
  color: var(--secondary-color);
  background: var(--tertiary-color);
}

.button-wobble {
  animation: wobble 0.15s ease;
}

@keyframes wobble {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03); /* Button grows */
  }
}